/* eslint-disable no-useless-escape */
import Vue from "@/helper/initVue";
import NewsletterRegistrationComponent from "@/components/prototype/content-elements/CeNewsletterRegistration.vue";

export const initNewsletterCe = () => {
  const el = document.getElementById("newsletter-registration");

  if (!el) {
    return;
  }

  try {
    const config = el?.getAttribute("data-config");
    const layout = el?.getAttribute("data-layout");
    const header = el?.getAttribute("data-header");
    const headerLayout = el?.getAttribute("data-header-layout");
    const subheader = el?.getAttribute("data-subheader");

    if (!config) {
      return;
    }

    new Vue({
      el,
      render: (h) =>
        h(NewsletterRegistrationComponent, {
          props: {
            header: header,
            headerLayout: headerLayout,
            subheader: subheader,
            config: config,
            layout: layout,
          },
        }),
    });
  } catch (err) {
    console.error('Failed to load module "NewsletterRegistrationComponent"');
  }
};

initNewsletterCe();
